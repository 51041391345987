<template>
  <mds-button-container
    right-aligned
  >
    <mds-button
      class="dashboard__quick-links-dialog__btn"
      type="button"
      variation="secondary"
      size="medium"
      @click="$emit('close-dialog', $event)"
    >
      {{ dialogCancelButtonText }}
    </mds-button>
    <mds-button
      class="dashboard__quick-links-dialog__btn"
      type="button"
      variation="primary"
      size="medium"
      :disabled="disableDialogActiveBtn"
      @click="$emit('action-dialog-button', $event)"
    >
      {{ dialogActionButtonText }}
    </mds-button>
  </mds-button-container>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button'

export default {
  components: {
    MdsButton,
    MdsButtonContainer,
  },
  props: {
    dialogCancelButtonText: {
      type: String,
      required: true,
    },
    dialogActionButtonText: {
      type: String,
      required: true,
    },
    disableDialogActiveBtn: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.mds-button-container___radar {
  .dashboard__quick-links-dialog__btn {
    padding-left: $mds-space-4-x;
    padding-right: $mds-space-4-x;
  }
}
</style>
