<template>
  <mds-section
    :heading-level="4"
    :size="6"
    :title="dialogTitle"
    class="dashboard__quick-links-dialog__section"
    border="primary"
    border-position="bottom"
    bold
  >
    <template>
      <div
        class="dashboard__quick-links-dialog__description"
      >
        {{ dialogDescription }}
      </div>
      <mds-form>
        <mds-fieldset>
          <mds-textarea
            v-if="isAddProviderNotes"
            hidden-label
            label="Add Notes"
            placeholder="Write notes in here…"
            @input="$emit('input', $event)"
          />
          <mds-input
            v-else
            v-bind="$attrs"
            label="Link Name"
            @input="$emit('input', $event)"
          />
        </mds-fieldset>
      </mds-form>
    </template>
  </mds-section>
</template>

<script>
import MdsSection from '@mds/section'
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsInput from '@mds/input'
import MdsTextarea from '@mds/textarea'

export default {
  components: {
    MdsSection,
    MdsForm,
    MdsFieldset,
    MdsInput,
    MdsTextarea,
  },
  props: {
    dialogTitle: {
      type: String,
      required: true,
    },
    dialogDescription: {
      type: String,
      required: true,
    },
    isAddProviderNotes: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
  .mds-section___radar.dashboard__quick-links-dialog__section {
    .mds-section--primary___radar.mds-section--border-bottom___radar {
      .mds-section__title___radar {
        padding-top: 0;
      }
    }
  }
  .dashboard__quick-links-dialog__description {
    padding: $mds-space-1-x 0 $mds-space-2-x;
  }
</style>
