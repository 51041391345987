<template>
  <mds-loader v-if="planDetailsAndFiltersLoading" />
  <content-template
    v-else
    :back="true"
    :heading="FileName"
    @back="handleBack"
  >
    <content-container
      v-if="blankStateAndSkipQuery"
      title="Warning"
      :description="errorMessageDisplay"
      class="data-plans-details__blank-page"
    />
    <content-container
      v-else
      title="Plan Details"
      class="plan-details"
    >
      <div class="data-plan-details__metadata">
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Plan ID: </span><span class="data-plan-details__metadata-value">{{ planDetails.PlanCode }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Plan Name:</span><span class="data-plan-details__metadata-value">{{ planDetails.PlanName }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Program: </span><span class="data-plan-details__metadata-value">{{ programCode }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Product: </span><span class="data-plan-details__metadata-value">{{ productCode }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Menu: </span><span class="data-plan-details__metadata-value">{{ menuName }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Provider: </span><span class="data-plan-details__metadata-value">{{ providerCode }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Fiduciary: </span><span class="data-plan-details__metadata-value">{{ fiduciary }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Start Date: </span><span class="data-plan-details__metadata-value">{{ startDate }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">End Date: </span><span class="data-plan-details__metadata-value">{{ endDate }}</span>
        </span>
        <span class="data-plan-details__metadata-item">
          <span class="data-plan-details__metadata-field">Status: </span><span class="data-plan-details__metadata-value">{{ status }}</span>
        </span>
      </div>
      <template v-slot:action>
        <mds-button
          id="download-files-action"
          variation="secondary"
          icon-right="caret-down"
          type="button"
          @click="openDownloadPopover"
        >
          Actions
        </mds-button>
        <mds-popover
          ref="popoverRef"
          v-model="displayDownloadPopover"
          :heading-level="5"
          position="bottom-left"
          title="Actions"
          title-hidden
          triggered-by="download-files-action"
          class="data-plan-details__popover"
        >
          <mds-button
            v-if="isSaved === null"
            variation="flat"
            type="button"
            @click="openModal"
          >
            Save Detail Page
          </mds-button>
          <template v-else>
            <mds-button
              variation="flat"
              type="button"
              @click="triggerModalRemovePageDialog"
            >
              Remove Detail Page
            </mds-button>
            <mds-button
              variation="flat"
              type="button"
              @click="triggerModalEditPageDialog"
            >
              Edit Detail Page Name
            </mds-button>
          </template>
          <mds-button
            v-if="fundsTableData.length"
            variation="flat"
            type="button"
            class="operations-historical-detail__popover-action"
            @click="downloadPlansData('FUNDS')"
          >
            Download Fund Level
          </mds-button>
          <mds-button
            v-if="compliancesTableData.length"
            variation="flat"
            type="button"
            class="operations-historical-detail__popover-action"
            @click="downloadPlansData('COMPLIANCE')"
          >
            Download Compliance Level
          </mds-button>
        </mds-popover>
      </template>
      <mds-section
        :bold="true"
        :collapsible="true"
        expanded
        :heading-level="4"
        :size="6"
        title="Fund Level"
        class="data-plan-detail-fund-level-section"
      >
        <mds-form class="data-plan-details__filters">
          <mds-fieldset horizontal>
            <mds-combo-box
              v-model="selectedClientFundCode"
              label="ClientFundCode:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterClientFundCodesOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedSecId"
              label="SecId:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterSecIdsOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedFiduciaryCategory"
              label="Fiduciary Category:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterFiduciaryCategoriesOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedFundStatus"
              label="Fund Status:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterFundStatusOptions"
              :multiple-item-limit="2"
            />
          </mds-fieldset>
        </mds-form>
        <div class="data-table-container">
          <div class="data-funds-additional-points">
            <mds-button
              id="clear-fund-filters"
              variation="flat"
              icon="refresh"
              type="button"
              class="operations-historical__clear"
              @click="clearFundsFilters"
            >
              Clear all filters
            </mds-button>
            <mds-button-container right-aligned>
              <mds-button
                id="additional-funds-data-action"
                icon-right="caret-down"
                variation="flat"
                @click="displayFundsAdditionalDataPoints = !displayFundsAdditionalDataPoints"
              >
                Additional Data Points
              </mds-button>
              <mds-popover
                v-model="displayFundsAdditionalDataPoints"
                width="500px"
                position="bottom-left"
                title="Choose additional data points to display"
                title-hidden
                triggered-by="additional-funds-data-action"
                class="data-plans-funds__additional-data-points"
              >
                <mds-form>
                  <mds-fieldset variation="checkbox-group">
                    <mds-checkbox
                      v-for="option in additionalFundsDataPoints"
                      :key="option.key"
                      v-model="option.selected"
                    >
                      {{ option.name }}
                    </mds-checkbox>
                  </mds-fieldset>
                </mds-form>
              </mds-popover>
            </mds-button-container>
          </div>
          <fade-transition>
            <mds-loader v-if="fundsDataLoading" />
            <!-- Include some error state -->
            <lazy-table
              v-else-if="!fundsDataLoading && fundsTableData.length > 0"
              :offset="500"
              :header-configs="fundsTableHeaders"
              :row-data="fundsLazyTableData"
              :pagination-config="tablePaginationConfig"
              pagination="below"
              class="data-plans-funds__table"
              @mds-data-table-page-change="handleFundsTablePageChange"
            />
            <mds-empty-state
              v-else
              title="No Plans Level Data Found"
              message="There are no fund level data that meet your search criteria."
              size="large"
              class="data-plans-funds__empty"
            >
              <template v-slot:mds-empty-state-icon>
                <img :src="`${publicPath}images/icons/empty-search.svg`">
              </template>
              <template v-slot:mds-empty-state-actions>
                <mds-button
                  variation="primary"
                  type="button"
                  @click="clearFundsFilters"
                >
                  Search Again
                </mds-button>
              </template>
            </mds-empty-state>
          </fade-transition>
        </div>
      </mds-section>
      <mds-section
        :bold="true"
        :collapsible="true"
        :heading-level="4"
        :size="6"
        title="Compliance Level"
        class="data-plan-detail-compliance-level-section"
      >
        <mds-form class="data-plan-details__filters">
          <mds-fieldset horizontal>
            <mds-combo-box
              v-model="selectedComplianceClientFundCode"
              label="ClientFundCode:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterComplianceClientFundCodesOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedErrorCode"
              label="Error Code:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterErrorCodesOptions"
              :multiple-item-limit="2"
            />
          </mds-fieldset>
        </mds-form>
        <div class="data-table-container">
          <div class="data-compliance-additional-points">
            <mds-button
              id="clear-compliance-filters"
              variation="flat"
              icon="refresh"
              type="button"
              class="operations-historical__clear"
              @click="clearComplianceFilters"
            >
              Clear all filters
            </mds-button>
            <mds-button-container right-aligned>
              <mds-button
                id="additional-compliance-data-action"
                icon-right="caret-down"
                variation="flat"
                @click="displayComplianceAdditionalDataPoints = !displayComplianceAdditionalDataPoints"
              >
                Additional Data Points
              </mds-button>
              <mds-popover
                v-model="displayComplianceAdditionalDataPoints"
                width="500px"
                position="bottom-left"
                title="Choose additional data points to display"
                title-hidden
                triggered-by="additional-compliance-data-action"
                class="data-plans-compliance__additional-data-points"
              >
                <mds-form>
                  <mds-fieldset variation="checkbox-group">
                    <mds-checkbox
                      v-for="option in additionalComplianceDataPoints"
                      :key="option.key"
                      v-model="option.selected"
                    >
                      {{ option.name }}
                    </mds-checkbox>
                  </mds-fieldset>
                </mds-form>
              </mds-popover>
            </mds-button-container>
          </div>
          <fade-transition>
            <mds-loader v-if="complianceDataLoading" />
            <!-- Include some error state -->
            <lazy-table
              v-else-if="!complianceDataLoading && compliancesTableData.length > 0"
              :offset="500"
              :header-configs="complianceTableHeaders"
              :row-data="complianceLazyTableData"
              :pagination-config="complianceTablePaginationConfig"
              pagination="below"
              class="data-plans-compliances__table"
              @mds-data-table-page-change="handleComplianceTablePageChange"
            />
            <mds-empty-state
              v-else
              title="No Compliance Level Data Found"
              message="There are no compliance level data that meet your search criteria."
              size="large"
              class="data-plans-compliance__empty"
            >
              <template v-slot:mds-empty-state-icon>
                <img :src="`${publicPath}images/icons/empty-search.svg`">
              </template>
              <template v-slot:mds-empty-state-actions>
                <mds-button
                  variation="primary"
                  type="button"
                  @click="clearComplianceFilters"
                >
                  Search Again
                </mds-button>
              </template>
            </mds-empty-state>
          </fade-transition>
        </div>
      </mds-section>
      <!-- Modal  -->
      <template v-if="isSaved === null">
        <dashboard-modal-save
          v-model="inputMyDetailsName"
          :toggle-modal="toggleModal"
          :disable-save-btn="disableSaveBtn"
          title="Save Details Page"
          description="Save this Details Page to add it to the &quot;My Details&quot; section of your Dashboard."
          @close-modal="closeModal"
          @action-modal-button="actionModalButton"
        />
      </template>
      <template v-else>
        <mds-dialog
          v-model="toggleDialog"
          action-required
          :width="dialogWidth"
        >
          <dashboard-dialog-edit-content
            v-if="editDialogOpen"
            v-model="inputMyDetailsName"
            dialog-title="Edit Details Page"
            dialog-description="This will appear in &quot;My Details&quot; section of the Dashboard"
          />
          <dashboard-dialog-delete-content
            v-if="removeDialogOpen"
            :dialog-title="activeRemoveTitle"
          />
          <template v-slot:mds-dialog-actions-right>
            <dashboard-dialog-buttons
              :dialog-cancel-button-text="dialogCancelButtonText"
              :dialog-action-button-text="dialogActionButtonText"
              :disable-dialog-save-btn="disableSaveBtn"
              @close-dialog="closeDialog"
              @action-dialog-button="actionDialogButton"
            />
          </template>
        </mds-dialog>
      </template>
    </content-container>
  </content-template>
</template>
<script>
import MdsLoader from '@mds/loader'
import ContentTemplate from '../../components/ContentTemplate/ContentTemplate'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import FadeTransition from '../../components/Transitions/FadeTransition'
import { MdsButtonContainer, MdsButton } from '@mds/button'
import MdsCheckbox from '@mds/checkbox'
import MdsEmptyState from '@mds/empty-state'
import MdsSection from '@mds/section'
import MdsComboBox from '@mds/combo-box'
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsPopover from '@mds/popover'
import MdsDialog from '@mds/dialog'
import LazyTable from '../../components/LazyTable/LazyTable'
import DashboardModalSave from '../../components/DashboardModalSave/DashboardModalSave'
import DashboardDialogDeleteContent from '../../components/DashboardDialogContent/DashboardDialogDeleteContent'
import DashboardDialogEditContent from '../../components/DashboardDialogContent/DashboardDialogEditContent'
import DashboardDialogButtons from '../../components/DashboardDialogContent/DashboardDialogButtons'
import sessionStorageMixin from '../../mixins/session-storage'
import { format, parseISO } from 'date-fns'
import { generateRandomId, formatDateFromIsoString } from '../../../shared/utils'
import { DATE_FORMAT } from '../../../shared/constants/dates'
import { PAGE_FILTERS_MAP, DATA_PLANS_DETAILS_NAME } from '../../../shared/constants/filters'
import { CACHE_FIRST, NETWORK_ONLY } from '../../graqhql/fetch-policies'
import { RadarViews } from '../../../shared/constants/radar-views'
import RoleAccess from '../../services/role-access-service'
import { CREATE_DASHBOARD_MY_DETAILS, DELETE_DASHBOARD_MY_DETAILS, UPDATE_DASHBOARD_MY_DETAILS_NAME } from '../../graqhql/mutations'
import {
  GET_DASHBOARD_IF_EXISTS_MY_DETAILS,
  GET_DASHBOARD_USER_ID,
  GET_PLAN_DETAILS,
  GET_PLAN_FUNDS_DATA,
  GET_PLAN_FUNDS_COUNT,
  GET_PLAN_COMPLIANCES_DATA,
  GET_PLAN_COMPLIANCES_COUNT,
  GET_CLIENT_FUND_CODES,
  GET_COMPLIANCE_CLIENT_FUND_CODES,
  GET_ERROR_CODES,
  GET_FIDUCIARY_CATEGORIES,
  GET_FUND_STATUS,
  GET_SEC_IDS,
  GET_IS_LIVE_PROVIDER,
} from '../../graqhql/queries'

const EMPTY_NEW_DETAILS_NAME = ''
const INITIAL_USER_ID = null

// Filter constants
const INITIAL_SELECTED_CLIENT_FUND_CODE = []
const INITIAL_SELECTED_COMPLIANCE_CLIENT_FUND_CODE = []
const INITIAL_SELECTED_ERROR_CODE = []
const INITIAL_SELECTED_FIDUCIARY_CATEGORY = []
const INITIAL_SELECTED_FUND_STATUS = []
const INITIAL_SELECTED_SEC_ID = []
const ALL_PLACEHOLDER = 'All'

const NUMBER_OF_ROWS_PER_PAGE_OPTIONS = [10, 20, 40, 80]
const NUMBER_OF_ROWS_PER_PAGE_DEFAULT = 40

const ColumnDisplayStates = {
  ALWAYS: 'ALWAYS', // A required column that should always be displayed.
  BY_DEFAULT: 'BY_DEFAULT', // A column that is initially displayed but can then be turned off.
  NOT_BY_DEFAULT: 'NOT_BY_DEFAULT', // A column that is not initially display but can then be turned on.
}

const FUND_TABLE_COLUMN_CONFIG = [
  {
    id: 'clientFundCode',
    name: 'Client Fund Code',
    width: '150px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'fundName',
    name: 'Fund Name',
    width: '200px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'secId',
    name: 'SecID',
    width: '100px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'ticker',
    name: 'Ticker',
    width: '100px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'cusip',
    name: 'CUSIP',
    width: '100px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'fundId',
    name: 'FundID',
    width: '100px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'dataStatus',
    name: 'Data Status',
    width: '100px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'morningstarCategory',
    name: 'Morningstar Category',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'fundStatus',
    name: 'Fund Status',
    width: '100px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'fiduciaryCategory',
    name: 'Fiduciary Category',
    width: '150px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'seriesId',
    name: 'Series ID',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'isUniversePreferred',
    name: 'Is Universe Preferred',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'clientDisplayName',
    name: 'Client Display Name',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'pendingFundStatus',
    name: 'Pending Fund Status',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'pendingFiduciaryCategory',
    name: 'Pending Fiduciary Category',
    width: '200px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'seriesId',
    name: 'Pending Series ID',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
]
const COMPLIANCE_TABLE_COLUMN_CONFIG = [
  {
    id: 'clientFundCode',
    name: 'Client Fund Code',
    width: '150px',
    display: ColumnDisplayStates.BY_DEFAULT,
  },
  {
    id: 'firstSuccessDate',
    name: 'First Success Date',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'terminationDate',
    name: 'Termination Date',
    width: '150px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'deadlineDate',
    name: 'Deadline Date',
    width: '150px',
    display: ColumnDisplayStates.BY_DEFAULT,
  },
  {
    id: 'ruleId',
    name: 'Error Code',
    width: '150px',
    display: ColumnDisplayStates.BY_DEFAULT,
  },
  {
    id: 'errorMessage',
    name: 'Error Message',
    width: '400px',
    display: ColumnDisplayStates.BY_DEFAULT,
  },
]
const initializeAdditionalDataPointConfig = (config) => {
  return config
    .filter(({ display }) => display !== ColumnDisplayStates.ALWAYS)
    .map(({ id, name, display }) => ({ id, name, selected: display === ColumnDisplayStates.BY_DEFAULT }))
}

export default {
  components: {
    MdsLoader,
    ContentTemplate,
    ContentContainer,
    FadeTransition,
    MdsButtonContainer,
    MdsButton,
    MdsCheckbox,
    MdsEmptyState,
    MdsSection,
    MdsComboBox,
    MdsForm,
    MdsFieldset,
    MdsDialog,
    MdsPopover,
    LazyTable,
    DashboardDialogEditContent,
    DashboardDialogDeleteContent,
    DashboardDialogButtons,
    DashboardModalSave,
  },
  mixins: [
    sessionStorageMixin({
      properties: PAGE_FILTERS_MAP[DATA_PLANS_DETAILS_NAME],
      suffix: DATA_PLANS_DETAILS_NAME,
      setUrlId: true,
    }),
  ],
  apollo: {
    userId: {
      query: GET_DASHBOARD_USER_ID,
      fetchPolicy: CACHE_FIRST,
    },
    clientFundCodes: {
      query: GET_CLIENT_FUND_CODES,
      variables () {
        return {
          id: this.currentPlanId,
        }
      },
    },
    isLiveProvider: {
      query: GET_IS_LIVE_PROVIDER,
      variables () {
        return {
          provider: this.$route.query.provider,
        }
      },
    },
    secIds: {
      query: GET_SEC_IDS,
      variables () {
        return {
          id: this.currentPlanId,
        }
      },
    },
    fiduciaryCategories: {
      query: GET_FIDUCIARY_CATEGORIES,
      variables () {
        return {
          id: this.currentPlanId,
        }
      },
    },
    fundStatuses: {
      query: GET_FUND_STATUS,
      variables () {
        return {
          id: this.currentPlanId,
        }
      },
    },
    complianceClientFundCodes: {
      query: GET_COMPLIANCE_CLIENT_FUND_CODES,
      variables () {
        return {
          id: this.currentPlanId,
        }
      },
    },
    errorCodes: {
      query: GET_ERROR_CODES,
      variables () {
        return {
          id: this.currentPlanId,
        }
      },
    },
    planDetails: {
      query: GET_PLAN_DETAILS,
      variables () {
        return { planId: this.currentPlanId }
      },
      skip () {
        return this.blankStateAndSkipQuery
      },
      error (error) {
        this.authError = error
      },
    },
    plansFundsData: {
      query: GET_PLAN_FUNDS_DATA,
      variables () {
        return {
          planId: this.currentPlanId,
          filters: this.planDetailsFundFilters,
          pagination: this.planFundsPagination,
        }
      },
      update: data => data.planFundsData,
      result () {
        /**
         * The BE API endpoint for plan funds data is paginated which means we only request a "slice" of data at a time.
         * As a small performance improvement, we can load sibling pages behind-the-scences each time we navigate
         * to a new page and store them in the cache. This allows for the user to navigate between pages without
         * ever seeing a loader. Fortunately, Apollo handles whether or not it should grab data from the cache or
         * go to the network to fetch data that hasn't already been cached.
         *
         * The data properties "planFundsDataNextPage" and "planFundsDataPrevPage" below aren't actually read from. They
         * are only used so Apollo can set the data somewhere.
         */
        this.fetchAdditonalPlanFundsDataPages()
      },
    },
    planFundsCountData: {
      query: GET_PLAN_FUNDS_COUNT,
      variables () {
        return {
          planId: this.currentPlanId,
          filters: this.planDetailsFundFilters,
        }
      },
      update: data => data.planFundsCount,
    },
    planCompliancesData: {
      query: GET_PLAN_COMPLIANCES_DATA,
      variables () {
        return {
          planId: this.currentPlanId,
          filters: this.planDetailsComplianceFilters,
          pagination: this.planCompliancesPagination,
        }
      },
      update: data => data.planCompliancesData,
      result () {
        /**
         * The BE API endpoint for plan Compliance data is paginated which means we only request a "slice" of data at a time.
         * As a small performance improvement, we can load sibling pages behind-the-scences each time we navigate
         * to a new page and store them in the cache. This allows for the user to navigate between pages without
         * ever seeing a loader. Fortunately, Apollo handles whether or not it should grab data from the cache or
         * go to the network to fetch data that hasn't already been cached.
         *
         * The data properties "planCompliancesDataNextPage" and "planCompliancesDataPrevPage" below aren't actually read from. They
         * are only used so Apollo can set the data somewhere.
         */
        this.fetchAdditionalCompliancesDataPages()
      },
    },
    planCompliancesCountData: {
      query: GET_PLAN_COMPLIANCES_COUNT,
      variables () {
        return {
          planId: this.currentPlanId,
          filters: this.planDetailsComplianceFilters,
        }
      },
      update: data => data.planCompliancesCount,
    },
    ifExistsMyDetails: {
      query: GET_DASHBOARD_IF_EXISTS_MY_DETAILS,
      variables () {
        return {
          options: {
            urlPath: window.location.href.split('#/')[1],
            userId: this.userId.id,
          },
        }
      },
      skip () {
        // if the user is not on apolloDB
        return this.userId === null
      },
      fetchPolicy: NETWORK_ONLY,
    },
  },
  data () {
    return {
      authError: null,
      isLiveProvider: null,
      selectedView: RoleAccess.selectedView,
      currentPlanId: this.$route.params.id,
      publicPath: process.env.BASE_URL,
      filtersPlaceholder: ALL_PLACEHOLDER,
      toggleDialog: false,
      inputMyDetailsName: EMPTY_NEW_DETAILS_NAME,
      editDialogOpen: false,
      removeDialogOpen: false,
      toggleModal: false,
      userId: INITIAL_USER_ID,
      ifExistsMyDetails: null,
      // Initialize Apollo properties
      clientFundCodes: [],
      secIds: [],
      fiduciaryCategories: [],
      fundStatuses: [],
      complianceClientFundCodes: [],
      errorCodes: [],
      planFundsCountData: { count: 0 },
      planFundsDataNextPage: null,
      planFundsDataPrevPage: null,
      planComplianceDataNextPage: null,
      planComplianceDataPrevPage: null,
      // End initialize Apollo properties
      fundsTablePaginationData: {
        pageSize: NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: 1,
        firstItem: 1,
      },
      complianceTablePaginationData: {
        pageSize: NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: 1,
        firstItem: 1,
      },
      displayFundsAdditionalDataPoints: false,
      displayComplianceAdditionalDataPoints: false,
      additionalFundsDataPoints: initializeAdditionalDataPointConfig(FUND_TABLE_COLUMN_CONFIG),
      additionalComplianceDataPoints: initializeAdditionalDataPointConfig(COMPLIANCE_TABLE_COLUMN_CONFIG),
      displayDownloadPopover: false,
      // Filters
      selectedClientFundCode: INITIAL_SELECTED_CLIENT_FUND_CODE,
      selectedSecId: INITIAL_SELECTED_SEC_ID,
      selectedFiduciaryCategory: INITIAL_SELECTED_FIDUCIARY_CATEGORY,
      selectedFundStatus: INITIAL_SELECTED_FUND_STATUS,
      selectedComplianceClientFundCode: INITIAL_SELECTED_COMPLIANCE_CLIENT_FUND_CODE,
      selectedErrorCode: INITIAL_SELECTED_ERROR_CODE,
    }
  },
  computed: {
    dialogCancelButtonText () {
      return this.editDialogOpen ? 'Cancel' : 'No'
    },
    dialogActionButtonText () {
      return this.editDialogOpen ? 'Save' : 'Yes'
    },
    disableSaveBtn () {
      return this.editDialogOpen && this.inputMyDetailsName === EMPTY_NEW_DETAILS_NAME
    },
    dialogWidth () {
      return this.editDialogOpen ? '500px' : '350px'
    },
    planDetailsAndFiltersLoading () {
      return this.$apollo.queries.isLiveProvider.loading ||
        this.$apollo.queries.planDetails.loading ||
        this.$apollo.queries.clientFundCodes.loading ||
        this.$apollo.queries.secIds.loading ||
        this.$apollo.queries.fiduciaryCategories.loading ||
        this.$apollo.queries.fundStatuses.loading ||
        this.$apollo.queries.complianceClientFundCodes.loading ||
        this.$apollo.queries.errorCodes.loading
    },
    blankStateAndSkipQuery () {
      return !!this.authError ||
      ((this.selectedView === RadarViews.TEST) && (this.isLiveProvider?.value === true)) ||
      ((this.selectedView === RadarViews.LIVE) && (this.isLiveProvider?.value === false)) ||
      this.$route.query.provider === undefined ||
      this.isLiveProvider === null
    },
    programCode () {
      return this.planDetails.ProgramCode || '—'
    },
    productCode () {
      return this.planDetails.ProductCode || '—'
    },
    providerCode () {
      return this.planDetails.ProviderCode || '—'
    },
    menuName () {
      return this.planDetails.MenuName || '—'
    },
    fiduciary () {
      return this.planDetails.FiduciaryCode || '—'
    },
    endDate () {
      return this.planDetails.EndDate ? format(parseISO(this.planDetails.EndDate), DATE_FORMAT) : '—'
    },
    startDate () {
      return this.planDetails.StartDate ? format(parseISO(this.planDetails.StartDate), DATE_FORMAT) : '—'
    },
    status () {
      return this.planDetails.Status || '—'
    },
    filterClientFundCodesOptions () {
      return [...this.clientFundCodes.map(({ code }) => ({ text: code, value: code }))]
    },
    filterSecIdsOptions () {
      return [...this.secIds.map(({ code }) => ({ text: code, value: code }))]
    },
    filterFiduciaryCategoriesOptions () {
      return this.fiduciaryCategories.map(fiduciary => ({ text: fiduciary.name, value: fiduciary.code }))
    },
    filterFundStatusOptions () {
      return this.fundStatuses.map(fund => ({ text: fund.name, value: fund.code }))
    },
    filterComplianceClientFundCodesOptions () {
      return [...this.complianceClientFundCodes.map(({ code }) => ({ text: code, value: code }))]
    },
    filterErrorCodesOptions () {
      return [...this.errorCodes.map(({ code }) => ({ text: code, value: code }))]
    },
    planDetailsFundFilters () {
      return {
        ...(this.selectedClientFundCode?.length > 0 ? { clientFundCode: this.selectedClientFundCode } : {}),
        ...(this.selectedSecId?.length > 0 ? { secId: this.selectedSecId } : {}),
        ...(this.selectedFiduciaryCategory?.length > 0 ? { fiduciaryCategory: this.selectedFiduciaryCategory } : {}),
        ...(this.selectedFundStatus?.length > 0 ? { fundStatus: this.selectedFundStatus } : {}),
      }
    },
    planDetailsComplianceFilters () {
      return {
        ...(this.selectedComplianceClientFundCode?.length > 0 ? { clientFundCode: this.selectedComplianceClientFundCode } : {}),
        ...(this.selectedErrorCode?.length > 0 ? { complianceErrorCode: this.selectedErrorCode } : {}),
      }
    },
    fundsDataLoading () {
      return this.$apollo.queries.plansFundsData.loading || this.$apollo.queries.planFundsCountData.loading
    },
    totalNumberOfFundsData () {
      return this.planFundsCountData?.count
    },
    fundsTableData () {
      return this.plansFundsData?.planFundsData ? this.plansFundsData?.planFundsData : []
    },
    fundsLazyTableData () {
      return this.fundsTableData.map(item => {
        return {
          id: `plan-funds-${generateRandomId()}`,
          ...item,
        }
      })
    },
    filteredAdditionalFundDataPoints () {
      return this.additionalFundsDataPoints
        .filter(datapoint => datapoint.selected)
        .map(datapoint => datapoint.id)
    },
    fundsTableHeaders () {
      return FUND_TABLE_COLUMN_CONFIG
        .filter(({ id, display }) => display === ColumnDisplayStates.ALWAYS || this.filteredAdditionalFundDataPoints.includes(id))
        .map(({ id, name, width }) => ({ fieldName: id, text: name, width }))
    },
    tablePaginationConfig () {
      return {
        pageSizes: NUMBER_OF_ROWS_PER_PAGE_OPTIONS,
        pageSize: this.fundsTablePaginationData.pageSize || NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: this.fundsTablePaginationData.page,
        totalItems: this.totalNumberOfFundsData,
        showItemsInfo: true,
        showItemsSelect: true,
      }
    },
    planFundsPagination () {
      return {
        page: this.fundsTablePaginationData.page,
        limit: this.fundsTablePaginationData.pageSize,
      }
    },
    totalNumberOfCompliancesData () {
      return this.planCompliancesCountData?.count
    },
    compliancesTableData () {
      return this.planCompliancesData?.planCompliancesData ? this.planCompliancesData?.planCompliancesData : []
    },
    complianceLazyTableData () {
      return this.compliancesTableData.map(item => {
        return {
          id: `plan-compliances-${generateRandomId()}`,
          clientFundCode: item.clientFundCode || '—',
          firstSuccessDate: item.firstSuccessDate ? formatDateFromIsoString(item.firstSuccessDate, DATE_FORMAT) : '—',
          terminationDate: item.terminationDate ? formatDateFromIsoString(item.terminationDate, DATE_FORMAT) : '—',
          deadlineDate: item.deadlineDate ? formatDateFromIsoString(item.deadlineDate, DATE_FORMAT) : '—',
          ruleId: item.ruleId || '—',
          errorMessage: item.errorMessage || '—',
        }
      })
    },
    complianceDataLoading () {
      return this.$apollo.queries.planCompliancesData.loading || this.$apollo.queries.planCompliancesCountData.loading
    },
    filteredAdditionalComplianceDataPoints () {
      return this.additionalComplianceDataPoints
        .filter(datapoint => datapoint.selected)
        .map(datapoint => datapoint.id)
    },
    complianceTableHeaders () {
      return COMPLIANCE_TABLE_COLUMN_CONFIG
        .filter(({ id, display }) => display === ColumnDisplayStates.ALWAYS || this.filteredAdditionalComplianceDataPoints.includes(id))
        .map(({ id, name, width }) => ({ fieldName: id, text: name, width }))
    },
    complianceTablePaginationConfig () {
      return {
        pageSizes: NUMBER_OF_ROWS_PER_PAGE_OPTIONS,
        pageSize: this.complianceTablePaginationData.pageSize || NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: this.complianceTablePaginationData.page,
        totalItems: this.totalNumberOfCompliancesData,
        showItemsInfo: true,
        showItemsSelect: true,
      }
    },
    planCompliancesPagination () {
      return {
        page: this.complianceTablePaginationData.page,
        limit: this.complianceTablePaginationData.pageSize,
      }
    },
    errorMessageDisplay () {
      if (this.blankStateAndSkipQuery) {
        return 'The user is not permitted to view this page based on their role or current data selection.'
      }
      return 'There was an issue fetching historical details for this record. Please try again.'
    },
    isSaved () {
      if (this.ifExistsMyDetails !== null && this.ifExistsMyDetails.length !== 0) {
        return this.ifExistsMyDetails[0]
      }
      return null
    },
    activeRemoveTitle () {
      return `Are you sure you want to remove ${this.isSaved.detailName} from your Details?`
    },
    FileName () {
      return (this.isSaved !== null) ? this.isSaved.detailName + ' ☆' : null
    },
  },
  methods: {
    openModal () {
      this.$refs.popoverRef.hide()
      this.toggleModal = true
    },
    closeModal () {
      this.toggleModal = false
      this.inputMyDetailsName = EMPTY_NEW_DETAILS_NAME
    },
    async createNewDetail () {
      this.toggleModal = false
      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_MY_DETAILS,
        variables: {
          options: {
            userId: this.userId.id,
            urlPath: window.location.href.split('#/')[1],
            providerCode: this.providerCode,
            fiduciaryCode: this.fiduciary,
            fileType: this.programCode,
            detailName: this.inputMyDetailsName,
            detailType: 'Plan',
            selectedView: this.selectedView,
          },
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.inputMyDetailsName} has been saved.`, tinted: true })
        this.inputMyDetailsName = EMPTY_NEW_DETAILS_NAME
        this.closeModal()
        this.$apollo.queries.ifExistsMyDetails.refetch()
      })
    },
    async updateMyDetailName () {
      this.toggleDialog = false
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_MY_DETAILS_NAME,
        variables: {
          options: {
            id: this.isSaved.id,
            detailName: this.inputMyDetailsName,
          },
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.inputMyDetailsName} has been saved.`, tinted: true })
        this.closeDialog()
        this.$apollo.queries.ifExistsMyDetails.refetch()
      })
    },
    async deleteMyDetail () {
      this.toggleDialog = false
      await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_MY_DETAILS,
        variables: {
          id: this.isSaved.id,
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.isSaved.detailName} has been deleted from My Details.`, tinted: true })
        this.closeDialog()
        this.$apollo.queries.ifExistsMyDetails.refetch()
      })
    },
    actionModalButton () {
      this.createNewDetail()
    },
    actionDialogButton () {
      if (this.editDialogOpen === true) {
        this.updateMyDetailName()
      }
      if (this.removeDialogOpen === true) {
        this.deleteMyDetail()
      }
    },
    closeDialog () {
      this.toggleDialog = false
      if (this.editDialogOpen === true) {
        this.inputMyDetailsName = EMPTY_NEW_DETAILS_NAME
        this.editDialogOpen = false
      }
      if (this.removeDialogOpen === true) {
        this.removeDialogOpen = false
      }
    },
    triggerModalEditPageDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.editDialogOpen = true
    },
    triggerModalRemovePageDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.removeDialogOpen = true
    },
    openDownloadPopover () {
      this.displayDownloadPopover = true
    },
    handleBack () {
      this.$router.push({ name: 'data-plans' })
    },
    handleFundsTablePageChange (data) {
      this.fundsTablePaginationData = data
    },
    handleComplianceTablePageChange (data) {
      this.complianceTablePaginationData = data
    },
    fetchAdditonalPlanFundsDataPages () {
      const query = (property, options) => this.$apollo.addSmartQuery(property, options)
      const options = (page) => ({
        query: GET_PLAN_FUNDS_DATA,
        variables: {
          planId: this.currentPlanId,
          filters: this.planDetailsFundFilters,
          pagination: { ...this.planFundsPagination, page },
        },
        update: data => data.planFundsData,
        fetchPolicy: CACHE_FIRST,
      })

      // Fetch the previous and next page of data
      const { page: currentPage } = this.planFundsPagination
      query('planFundsDataNextPage', options(currentPage + 1))

      if (currentPage > 1) {
        query('planFundsDataPrevPage', options(currentPage - 1))
      }
    },
    fetchAdditionalCompliancesDataPages () {
      const query = (property, options) => this.$apollo.addSmartQuery(property, options)
      const options = (page) => ({
        query: GET_PLAN_COMPLIANCES_DATA,
        variables: {
          planId: this.currentPlanId,
          filters: this.planDetailsComplianceFilters,
          pagination: { ...this.planCompliancesPagination, page },
        },
        update: data => data.planCompliancesData,
        fetchPolicy: CACHE_FIRST,
      })

      // Fetch the previous and next page of data
      const { page: currentPage } = this.planCompliancesPagination
      query('planComplianceDataNextPage', options(currentPage + 1))

      if (currentPage > 1) {
        query('planComplianceDataPrevPage', options(currentPage - 1))
      }
    },
    async downloadPlansData (levelType) {
      this.$notifications.informational({ title: 'Your download has been initiated!', text: 'This could take a few seconds.' })

      try {
        const { ...filters } = levelType === 'FUNDS' ? this.planDetailsFundFilters : this.planDetailsComplianceFilters
        Object.entries(filters).forEach(([key, values]) => {
          filters[key] = values.join()
        })
        const plansOptions = {
          planId: this.currentPlanId,
          includeFundLevel: levelType === 'FUNDS',
          includeComplianceLevel: levelType === 'COMPLIANCE',
        }
        const config = {
          params: { ...plansOptions, ...filters },
          responseType: 'blob',
          withCredentials: true, // Pass cookies
        }
        const response = await this.$fetch.get(`${process.env.VUE_APP_API_URL}/download/plans`, { config })
        const filename = response.headers['content-disposition'].split('filename=')[1]
        this.$file.downloadBlobFile(filename, response.data)
        this.$notifications.success({ text: 'Your download has completed!', tinted: true })
      } catch (err) {
        this.$notifications.error({ text: 'There was an issue downloading the plans data. Please try again.', tinted: true, persistent: true })
      }
    },
    clearFundsFilters () {
      this.selectedClientFundCode = INITIAL_SELECTED_CLIENT_FUND_CODE
      this.selectedSecId = INITIAL_SELECTED_SEC_ID
      this.selectedFiduciaryCategory = INITIAL_SELECTED_FIDUCIARY_CATEGORY
      this.selectedFundStatus = INITIAL_SELECTED_FUND_STATUS
    },
    clearComplianceFilters () {
      this.selectedComplianceClientFundCode = INITIAL_SELECTED_COMPLIANCE_CLIENT_FUND_CODE
      this.selectedErrorCode = INITIAL_SELECTED_ERROR_CODE
    },
  },
}
</script>

<style lang="scss">

.operations-historical-detail__popover{
  .mds-section__content___radar{
    padding: $mds-space-1-and-a-half-x 0;
  }
  display: flex;
    div {
      width: 300px;
      overflow-x: hidden;
    }
    .mds-button___radar {
      margin: $mds-space-quarter-x;
      padding: $mds-space-three-quarter-x $mds-space-1-and-a-half-x;
      width: 100%;
      text-align: left;
      &:hover, &:active, &:focus {
        background-color: $mds-background-color-light-gray;
      }
    }
}

.data-plan-details__popover{
  .mds-section__content___radar{
    padding: $mds-space-1-and-a-half-x 0;
  }
  display: flex;
    div {
      width: 300px;
      overflow-x: hidden;
    }
    .mds-button___radar {
      margin: $mds-space-quarter-x;
      padding: $mds-space-three-quarter-x $mds-space-1-and-a-half-x;
      width: 100%;
      text-align: left;
      &:hover, &:active, &:focus {
        background-color: $mds-background-color-light-gray;
      }
    }
}

.data-plans-details__blank-page {
  text-align: center;
}

.data-plan-details__metadata {
  margin: 0 0 $mds-space-2-x 0;
}

.data-plan-details__metadata-item {
  display: inline-flex;
  @include mds-body-text-l();
  margin-bottom: $mds-space-2-x;

  &:not(:last-child) {
    padding-right: $mds-space-1-x;
    margin-right: $mds-space-1-x;
    border-right: $mds-border-separator-on-light;
  }
}
.data-plan-details__metadata-field {
  font-weight: bold;
  margin-right: $mds-space-1-x;;
}

.data-plan-detail-fund-level-section{
  margin: 0 0 $mds-space-5-x 0;
}

.data-plan-details__filters {
  justify-self: stretch;
  display: flex;
  align-items: flex-end;
}
.data-table-container {
  margin: 20px 0 0 0;
}
.data-funds-additional-points {
  margin: 0 0 20px 0;
}
.data-plans-funds__empty {
  margin: auto;
}
.data-plans-compliance__empty {
  margin: auto;
}
</style>
<style lang="scss" module>
// MDS overrides
:global(.data-plan-details__filters) {
  .mds-fieldset__horizontal > .mds-label {
    flex: 1 0 0;
    margin-bottom: 0;
  }
  .mds-fieldset {
    flex-grow: 2;
  }
}
:global(.data-plans-funds__additional-data-points) {
  .mds-fieldset {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
}
:global(.data-plan-detail-fund-level-section) {
  .mds-data-table__scroller {
    overflow: auto;
  }
}
</style>
