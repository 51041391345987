<template>
  <div class="dashboard-quick-link-delete-content">
    {{ dialogTitle }}
  </div>
</template>

<script>
export default {
  props: {
    dialogTitle: {
      type: String,
      required: true,
    },
  },
}
</script>
